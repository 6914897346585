// i18next-extract-mark-ns-start sepa-request-to-pay

//Payment methods
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import srtp_monei from 'images/sepa_monei.svg';

// Clients
import index1 from 'images/payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {AnchorLink} from 'components/AnchorLink';
import {List} from 'components/List';
import {PaymentMethodsLinkList} from 'components/landings/PaymetMethodsLinkList';
import {IndexBackground} from 'components/landings/IndexBackground';
import {MainTitleContent} from 'components/payment-methods/MainTitleContent';
import {MainTitleImg} from 'components/payment-methods/MainTitleImg';
import {BlogLink} from 'components/links/Blog';
import {SignUpLink} from 'components/links/Other';
import {IntegrationGuide} from 'components/payment-methods/IntegrationGuide';
import {InternalPageLink} from 'components/links/Pages';

const LargeSectionHeader = styled(SectionHeader)`
  h1 {
    font-size: 2.625rem;
    line-height: 3.25rem;

    @media (max-width: 768px) {
      font-size: 2rem;
      line-height: 2.75rem;
    }
  }
`;

const OrderList = styled.ol``;

const SepaRequestToPay: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        title={t('SEPA Request-to-Pay (SRTP)')}
        description={t(
          'Accept instant payments across Europe with SEPA Request-to-Pay. Request payment from customers to simplify the process and guarantee payment. Get MONEI »'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <MainTitleContent sx={{maxWidth: {all: '590px', md: '100%'}}}>
              <LargeSectionHeader underline tagName="h1">
                <Trans>SEPA Request-to-Pay (SRTP)</Trans>
              </LargeSectionHeader>
              <Trans parent="p">
                Created by the European Payments Council (EPC), SEPA Request-to-Pay (SRTP) is a
                frictionless and instant payment framework that lets European companies request
                physical or online payments (up to 100K euros) from customers.
              </Trans>
              <Trans parent="p">
                Like SEPA Direct Debit, SRTP will soon become a standard for national and
                international payments within the EU. And MONEI will be one of the first payment
                service providers to help businesses accept SRTP, thanks to our partnership with the
                EPC.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </MainTitleContent>

            <MainTitleImg src={srtp_monei} alt="MONEI SRTP" title="MONEI SRTP" />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Benefits of accepting SEPA Request-to-Pay</Trans>
              </SectionHeader>

              <Trans parent="p">
                Technically speaking, SEPA Request-to-Pay is not a{' '}
                <InternalPageLink slug="payment-methods">payment method</InternalPageLink>. It’s a
                messaging system that allows a payee to request the initiation of a payment from a
                payer via SMS, <InternalPageLink slug="features/whatsapp-payments">WhatsApp</InternalPageLink>, or email.
              </Trans>
              <Trans parent="p">
                With SRTP, you hold the power to request payment from customers (instead of them
                initiating payments). You’ll simplify and streamline the payment process, making it{' '}
                <BlogLink slug="frictionless-payments">frictionless</BlogLink> and guaranteeing
                payment.
              </Trans>
              <Trans parent="p">Advantages of accepting SRTP <BlogLink slug="instant-payment">instant payments</BlogLink>:</Trans>
              <List>
                <Trans parent="li">Heightened security</Trans>
                <Trans parent="li">Flexibility for you and your customers</Trans>
                <Trans parent="li">
                  Allows for <BlogLink slug="cross-border-payments">cross-border payments</BlogLink>
                </Trans>
                <Trans parent="li">Real-time payment processing, 24/7, 365 days a year</Trans>
                <Trans parent="li">Immediate availability of funds</Trans>
                <Trans parent="li">Ability to request in-store and online payments</Trans>
              </List>
              <Trans parent="p">
                SRTP is a European payment standard in the making. Adding this soon to be widely
                used omnichannel payment option to your payment stack is a no-brainer, especially if
                you’re looking to grow a{' '}
                <BlogLink slug="cross-border-ecommerce">cross-border e-commerce</BlogLink> business.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>SEPA Request-to-Pay payment process</Trans>
              </SectionHeader>
              <Trans parent="p">
                The experience for the payer is similar to other open banking payments, but the
                payment request channels and security depend on the{' '}
                <BlogLink slug="what-is-a-psp">payment service provider (PSP)</BlogLink>.
                Regardless, PSPs must comply with strict rules created by the{' '}
                <AnchorLink href="https://www.europeanpaymentscouncil.eu/what-we-do/other-schemes/sepa-request-pay-scheme">
                  European Payments Council
                </AnchorLink>
                .
              </Trans>
              <Trans parent="p">
                Use{' '}
                <AnchorLink href="https://www.europeanpaymentscouncil.eu/news-insights/news/new-srtp-participant-0">
                  MONEI to configure SRTP
                </AnchorLink>{' '}
                and securely request instant payments:
              </Trans>
              <OrderList>
                <Trans parent="li">
                  You request the initiation of a payment via SMS, WhatsApp, or email
                </Trans>
                <Trans parent="li">Payer confirms or rejects the request</Trans>
                <Trans parent="li">
                  After confirming the request, the payer chooses to pay immediately or later
                </Trans>
                <Trans parent="li">
                  Settlement of the transaction is based on the payees bank or card issuer, not SRTP
                </Trans>
              </OrderList>
              <Trans>
                Improve the payment experience with SEPA Request-to-Pay, but don’t stop there. Boost
                sales by accepting many{' '}
                <InternalPageLink slug="payment-methods">payment methods</InternalPageLink> including{' '}
                <InternalPageLink slug="payment-methods/credit-cards">cards</InternalPageLink> and{' '}
                <BlogLink slug="alternative-payment-methods">alternative</BlogLink> and{' '}
                <BlogLink slug="local-payment-methods">local payment methods</BlogLink> like{' '}
              </Trans>
              <PaymentMethodsLinkList exclude={['sepa-request-to-pay', 'credit-cards']} />,{' '}
              <Trans>and more.</Trans>
              <SectionActions>
                <SignUpButton variant="dark">
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
        </Content>
      </Background>
      <IntegrationGuide
        title={
          <>
            <Trans>Get the SEPA Request-to-Pay integration</Trans>
          </>
        }>
        <Trans>
          MONEI will be the first Spanish fintech to{' '}
          <BlogLink slug="instant-payments-europe-sepa-request-to-pay">
            offer instant payments across Europe
          </BlogLink>{' '}
          with SEPA Request-to-Pay. <SignUpLink>Register now</SignUpLink> to get on the waitlist —
          test integrations and other payment methods while you wait. We’ll notify you as soon as
          SRTP is available. Questions?{' '}
          <AnchorLink href="https://support.monei.com/hc/requests/new">Get in touch</AnchorLink>.
        </Trans>
      </IntegrationGuide>
    </>
  );
};

export default SepaRequestToPay;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "sepa-request-to-pay"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
